
:root {
  --darkbg: rgb(8, 1, 114);
  --darkpurple: rgb(51, 6, 155);
  --lightpurple: rgb(128, 72, 219);
  --lightblue: rgb(64, 140, 211);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: var(--darkbg);
  font-family: Gill Sans, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  background-color: black;
}

nav {
  display:flex;
  justify-content: center;
  background-color: black;
  align-items: center;
  vertical-align: middle;
  /* padding-left: 0.75em;
  padding-right: 1.5em; */
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}


nav h1{
  font-size: 3vw;
}

nav li {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  color: white;
}

nav a {
  line-height: inherit;
}

nav a.active{
  background-color: var(--darkpurple);
}

nav a:hover {
  background-color: var(--lightpurple);
}

main {
  background-image: linear-gradient(180deg, var(--darkpurple),var(--darkbg));
  padding: 0em 0.75em;
}

h2 {
  margin: 0px;
  padding: 0.75em 0em;
  font-family: 'Courier New', Courier, monospace;
}

div.about {
  display: flex;
  flex-wrap: wrap;
}

div.about img {
  width: 150px;
  padding: 10px 0px;
}

div.project-list, .posts-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

div.project-card {
  background-color: rgba(255,255,255,0.2);
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 10px black;
  border-radius: 2px;
  padding: 5px;
  margin: 1em;
  max-width: 300px;
  position: relative;
}


div.project-card img {
  width: 100%;
  border-radius: 2px;
}

div.project-title {
  font-weight: 900;
  text-align: center;
}

div.project-card .project-tech-list {
  padding: 10px 0px;
  display: flex;
  flex-wrap: wrap;
}

span.tech {
  padding: 5px; 
  margin: 5px 5px;
  background-color: rgba(255,255,255,0.2);
  border-radius: 10px;
}

.project-card .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 99%;
  width: 99%;
  opacity: 0;
  transition: .7s ease;
  background-color: var(--lightpurple);
  padding: 10px;
}

div.project-card:hover .overlay {
  opacity: 1;
}

.overlay .hover-info {
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.overlay .hover-info a {
  color: white;
}

div.posts-list {
  display: flex;
  flex-direction: column;
}

div.post-card {
  flex: 0 3;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
  margin: 10px;
  background-color: rgba(255,255,255,0.2);
}

div.post-card:hover{
  cursor: pointer;
}

div.post-card img{
  max-width: 95%;
  max-height: 200px;
  margin: 5px;
}

div.post-info{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  color: #93abf0;
  padding: 10px;
  flex: 1;
}

.post-info h5{
  font-weight: 700;
}

div.post-info a{
  color: white;
}

.post-info time{
  color: white;
}

div.social {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
  padding: 5px;
}

div.social a {
  text-align: center;
  /* text-decoration: none; */
  color: white;
  padding: 0em 0.5em;
}

div.resume a {
  text-decoration: none;
  color: var(--lightblue);
  font-weight: 600;
}

div.pdf-link {
  padding: 10px 0px;
}

iframe.resume-viewer {
  display: block;
  margin: 0 auto;
  width: 600px;
  height: 600px;
  max-width: 95%;
}

body.c14 {
  padding: 10px;
}

div.center {
  text-align: center;
}
